import { defaultThemes } from "hoc/AppTemplateWrapper/enum";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const { DARK_MODE_DEFAULT } = defaultThemes;

const SeoWrapper = ({ title }) => {
    const {
        chatSettings: { companyLogo, teamName, defaultTheme },
    } = useSelector((state) => state.chat);

    const isDarkMode = defaultTheme === DARK_MODE_DEFAULT;
    useEffect(() => {
        document.getElementById("favicon").href = companyLogo;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Helmet>
            <title>{`${teamName}  ${title ? title : ""}`}</title>
            <meta
                name='theme-color'
                content={isDarkMode ? "#1f272d" : "white"}
            />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1 interactive-widget=resizes-content"/>
        </Helmet>
    );
};

export default SeoWrapper;
