import ChatHeaderBannerMessage from "components/Chat/ChatModule/ChatHeader/ChatHeaderBannerMessage/ChatHeaderBannerMessage";

const { validateEmail } = require("utils/helper");

const LiveChatStatusBarBanner = ({
    user,
    isNotTablet,
    hasWebHookEnabled,
    handleAddEmailAction,
}) => {
    return (
        <>
            {!validateEmail(user?.email) ? (
                <>
                    {isNotTablet && !hasWebHookEnabled && (
                        <ChatHeaderBannerMessage
                            handleVerifyAction={handleAddEmailAction}
                            clickAction={!validateEmail(user?.email)}
                            message={
                                <>
                                    To save your ticket, click{" "}
                                    <span className='highlight underline'>
                                        here
                                    </span>{" "}
                                    to confirm your email.
                                </>
                            }
                        />
                    )}
                </>
            ) : (
                <>
                    {isNotTablet && !hasWebHookEnabled ? (
                        <ChatHeaderBannerMessage
                            closeAction={validateEmail(user?.email)}
                            message={
                                <>
                                    You can always re-open this ticket with the
                                    link we sent to{" "}
                                    <span className='highlight'>
                                        {user?.email}
                                    </span>
                                </>
                            }
                        />
                    ) : (
                        <>
                            <ChatHeaderBannerMessage
                                closeAction={validateEmail(user?.email)}
                                className='chat__header__banner__workmode__wrapper'
                                message={<>Click to view notice</>}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default LiveChatStatusBarBanner;
