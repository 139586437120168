const IframeLoader = () => {
    return (
        <div className='custom-iframe-loader'>
            <div class='iframe-spinner'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default IframeLoader;
