import EmailForm from "components/SignInForm/EmailForm/EmailForm";
import OTPForm from "components/SignInForm/OTPForm/OTPForm";
import ErrorView from "components/common/ErrorView/ErrorView";
import { DotLoader } from "components/ui";
import BannerMessage from "components/ui/BannerMessage/BannerMessage";
import CustomerVerifySuccess from "../CustomerVerifySuccess/CustomerVerifySuccess";
import { validateEmail } from "utils/helper";
import { dataQueryStatus } from "utils/formatHandlers";

export const verifystages = {
    initial: "INPUT_EMAIL",
    final: "INPUT_OTP",
    success: "SUCCESS",
};

const { initial, final, success } = verifystages;

const { ERROR, DATAMODE, LOADING } = dataQueryStatus;

const CustomerVerificationContainer = ({
    toggleBannerMessage,
    showBannerMessage,
    status,
    customer,
    handleEmailRequestUpdate,
    isSaveConvoAction,
    verifyStage,
    initialStepRequest,
    email,
    userId,
    handleVerifyAction,
    ticketId,
    setVerifyStage,
    isLinkEmail,
    messages,
    errorMssg,
    linkEmail,
}) => {
    const renderBasedOnStage = () => {
        switch (verifyStage) {
            case initial:
                return (
                    <EmailForm
                        userId={customer?.userId}
                        handleEmailRequestUpdate={handleEmailRequestUpdate}
                        title={
                            isSaveConvoAction
                                ? "Save conversation"
                                : "Verify email address"
                        }
                        subTitle={
                            isSaveConvoAction
                                ? "Kindly give us your email address so we can save this conversation"
                                : "We’ll send you a 4 digit OTP to ensure this is your email address."
                        }
                        bottomText='This email address will be used to communicate updates with you.'
                    />
                );

            case final:
                return (
                    <OTPForm
                        initialStepRequest={initialStepRequest}
                        pinLength={4}
                        redirectUser={false}
                        userId={customer?.userId}
                        handleSuccess={handleSuccess}
                        isDirectUser={true}
                        isLinkEmail={isLinkEmail}
                        title={`We’ve sent an OTP to your email`}
                        subTitle={
                            validateEmail(email || userId) &&
                            `at ${email || userId}`
                        }
                    />
                );

            case success:
                return (
                    <CustomerVerifySuccess
                        closeModal={handleVerifyAction}
                        redirectUser={isLinkEmail}
                        messages={messages}
                        ticketId={ticketId}
                    />
                );

            default:
                return "";
        }
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return (
                    <div className='customer-verify__loader__container'>
                        <DotLoader />
                    </div>
                );
            case DATAMODE:
                return <>{renderBasedOnStage()}</>;
            case ERROR:
                return (
                    <ErrorView message={errorMssg} retry={() => linkEmail()} />
                );
            default:
                return "";
        }
    };

    const handleSuccess = () => {
        setVerifyStage(verifystages.success);
    };

    return (
        <>
            <div
                className={`customer-verify__form  ${
                    verifyStage !== success ? "customer-verify--margin" : ""
                }`}>
                <div>
                    {showBannerMessage && (
                        <div className='customer-verify__banner__message__wrapper'>
                            <BannerMessage
                                onClose={() => toggleBannerMessage(false)}
                                isCloseable={true}>
                                We will never ask you for your PIN or password
                            </BannerMessage>
                        </div>
                    )}
                    {renderBasedOnStatus()}
                </div>
            </div>
        </>
    );
};

export default CustomerVerificationContainer;
