import React from "react";
import { useSelector } from "react-redux";
import imageLinks from "assets/images";
import { dataQueryStatus } from "utils/formatHandlers";
import SmallLoader from "components/ui/SmallLoader/SmallLoader";

import { useWindowSize } from "utils/hooks";
import "./LiveChatStatusBar.scss";

import LiveChatStatusBarBanner from "./LiveChatStatusBarBanner/LiveChatStatusBarBanner";
import LiveChatStatusBarMessage from "./LiveChatStatusBarMessage/LiveChatStatusBarMessage";

const { IDLE, LOADING, ERROR, DATAMODE } = dataQueryStatus;

const LiveChatStatusBar = ({
    status,
    errorMssg,
    reconnectUser,
    handleAddEmailAction,
    networkStatus,
}) => {
    const { user } = useSelector((state) => state?.auth);

    const { hasWebHookEnabled } = useSelector(
        (state) => state?.chat?.chatSettings
    );

    const handleRetry = () => {
        reconnectUser?.();
    };

    const { width } = useWindowSize();

    const isNotTablet = width > 768;

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <SmallLoader otherClassName={"primary"} />;

            case ERROR:
                return (
                    <span className='error__status' onClick={handleRetry}>
                        <img src={imageLinks?.svg.redRetry} alt='Retry Icon' />
                        {errorMssg}
                    </span>
                );

            case DATAMODE:
                return (
                    <>
                        <LiveChatStatusBarMessage
                            {...{
                                user,
                                handleRetry,
                                networkStatus,
                            }}
                        />

                        <LiveChatStatusBarBanner
                            {...{
                                user,
                                isNotTablet,
                                hasWebHookEnabled,
                                handleAddEmailAction,
                            }}
                        />
                    </>
                );

            default:
                return "";
        }
    };

    return <div className='live__chat--status'>{renderBasedOnStatus()}</div>;
};

export default LiveChatStatusBar;
