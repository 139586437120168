import "./SkeletonLoader.scss";

const SkeletonLoader = ({ counts = 10 }) => {
    return (
        <>
            <div className='skeleton-loader'>
                {Array.from({ length: counts }, (_, k) => (
                    <span className='skeleton-box' key={k}></span>
                ))}
            </div>
        </>
    );
};

export default SkeletonLoader;
