import React from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { DotLoader } from "components/ui";
import { defaultTemplates } from "hoc/AppTemplateWrapper/enum";
import { isLiveApp } from "config/config";
import "./FullPageLoader.scss";

const { WORKMODE } = defaultTemplates;

const FullPageLoader = () => {
    const { defaultTemplate} = useSelector((state) => state.chat.chatSettings);

    const isWorkModeTemplate = defaultTemplate === WORKMODE;

    let params = queryString.parse(window.location.search);
    const workspaceSlug = params?.workspaceSlug;
    const infiniteName = isLiveApp
        ? window.location.host.includes("metacare")
            ? window.location.host?.split(".")[0]
            : window.location.host
        : workspaceSlug;
    

    return (
        <div className='full__page_loader'>
            {isWorkModeTemplate ? (
                <span id='metaInfinite'>{ (infiniteName === "pocket" ? "PocketApp" : infiniteName) || "metacare" }</span>
            ) : (
                <DotLoader background={false} />
            )}
        </div>
    );
};

export default FullPageLoader;
