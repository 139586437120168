const imageLinks = {
    svg: {
        attention:
            "https://res.cloudinary.com/metacare/image/upload/v1661240831/ic_Information_er4k4y.svg",
        leaveIcon:
            "https://res.cloudinary.com/metacare/image/upload/v1658366039/Vector_mrbwv9.svg",
        verticalGrey:
            "https://res.cloudinary.com/metacare/image/upload/v1658278459/Shape_dfwuev.svg",
        crossIconGrey:
            "https://res.cloudinary.com/metacare/image/upload/v1645569938/cross_1_f1infl.svg",
        sendIcon: `https://res.cloudinary.com/metacare/image/upload/v1655970938/icons/Send_nputha.svg`,
        logo: "https://res.cloudinary.com/metacare/image/upload/v1658797121/Logo_Metacare_relayx.svg",
        cancel: "https://res.cloudinary.com/metacare/image/upload/v1645569938/cross_1_f1infl.svg",
        chat_icon:
            "https://res.cloudinary.com/metacare/image/upload/v1655681382/icons/Vector_1_qclow2.svg",
        info: "https://res.cloudinary.com/metacare/image/upload/v1655459906/icons/Combined_Shape_px9mhr.svg",
        success:
            "https://res.cloudinary.com/metacare/image/upload/v1649759463/icons/Ellipse_12_xicbrf.svg",
        warning:
            "https://res.cloudinary.com/metacare/image/upload/v1649762136/icons/Ellipse_14_oyfxhu.svg",
        danger: "https://res.cloudinary.com/metacare/image/upload/v1649762136/icons/Ellipse_15_qnpscb.svg",
        add: "https://res.cloudinary.com/metacare/image/upload/v1657295959/plus_ge4tti.svg",
        upload_image:
            "https://res.cloudinary.com/metacare/image/upload/v1649858627/icons/Shape_dl8p4w.svg",
        attachment:
            "https://res.cloudinary.com/metacare/image/upload/v1649858632/icons/Shape_kxejem.svg",
        send: "https://res.cloudinary.com/metacare/image/upload/v1649858641/icons/Shape_vilthf.svg",
        anchorDown:
            "https://res.cloudinary.com/metacare/image/upload/v1642339577/icons/icon_n7beax.svg",
        horizontalEllipsis:
            "https://res.cloudinary.com/metacare/image/upload/v1642339500/icons/icon_wocjvm.svg",
        leftArrow:
            "https://res.cloudinary.com/metacare/image/upload/v1642339360/icons/icon_iade0v.svg",
        remove: "https://res.cloudinary.com/metacare/image/upload/v1659960601/icons/remove_glyhiz.svg",
        document:
            "https://res.cloudinary.com/metacare/image/upload/v1659960640/icons/document_oojnni.svg",
        play: "https://res.cloudinary.com/metacare/image/upload/v1659960678/icons/play_e7fdhn.svg",
        loading:
            "https://res.cloudinary.com/metacare/image/upload/v1659970761/icons/loading_c47v6s.svg",
        retry: "https://res.cloudinary.com/metacare/image/upload/v1660125326/icons/Group_1000003017_h4hzpp.svg",
        abort: "https://res.cloudinary.com/metacare/image/upload/v1660125321/icons/Group_1000003016_qnwr0r.svg",
        mssgAlert:
            "https://res.cloudinary.com/metacare/image/upload/v1642340068/icons/icon_rbefsu.svg",
        check: "https://res.cloudinary.com/metacare/image/upload/v1642340363/icons/icon_yfq9tn.svg",
        cancelX:
            "https://res.cloudinary.com/metacare/image/upload/v1642338302/icons/icon_x3uj2c.svg",
        plainInfo:
            "https://res.cloudinary.com/metacare/image/upload/v1660640291/icons/InfoIcon_c4osdm.svg",
        clock: "https://res.cloudinary.com/metacare/image/upload/v1662667949/icons/clock_kxlqsk.svg",
        error: "https://res.cloudinary.com/metacare/image/upload/v1643030939/icons/icon_xdmgjv.svg",
        redRetry:
            "https://res.cloudinary.com/metacare/image/upload/v1672180300/icons/Vector_ys03lu.svg",
        themeSun:
            "https://res.cloudinary.com/metacare/image/upload/v1672644783/icons/fi-rr-sun_keqywy.svg",
        bookmark:
            "https://res.cloudinary.com/metacare/image/upload/v1672644783/icons/fi-rr-bookmark_dyezj3.svg",

        attachment2:
            "https://res.cloudinary.com/metacare/image/upload/v1674655585/icons/ic_Photos_bxnxdr.svg",

        save: "https://res.cloudinary.com/metacare/image/upload/v1675265111/icons/Vector_zhavz8.svg",
        close: "https://res.cloudinary.com/metacare/image/upload/v1676971243/icons/Shape_jkbaea.svg",
        calendar:
            "https://res.cloudinary.com/metacare/image/upload/v1679568105/fi-rr-calendar_xa1fhr.svg",
        successCheck:
            "https://res.cloudinary.com/metacare/image/upload/v1680852130/Frame_1000003452_pek73z.svg",
        blackPlusIcon:
            "https://res.cloudinary.com/metacare/image/upload/v1683046317/4115237-add-plus_2_dzqhb1.svg",
    },
};

export default imageLinks;
