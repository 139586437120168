import SmallLoader from "components/ui/SmallLoader/SmallLoader";

const FileUploadWrapperLoader = ({ isUploading, loaderClass, children }) => {
    return (
        <>
            {isUploading ? (
                <div className={loaderClass}>
                    <SmallLoader />
                </div>
            ) : (
                children
            )}
        </>
    );
};

export default FileUploadWrapperLoader;
