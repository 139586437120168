import { inAppAuthActions } from "components/SignInForm/enum";
import { AgentImage } from "components/ui";
import { useSelector } from "react-redux";

const { OPEN_OLD_CONVERSATIONS } = inAppAuthActions;

const InAppChatInterfaceMessage = ({
    handleInitialRequestUpdate,
    routeToChat,
    pinnedConversations,
}) => {
    const {
        chatSettings: { companyLogo, teamName },
    } = useSelector((state) => state.chat);

    return (
        <section className='in-app-chat-interface__content custom-scroll'>
            <div className={`message__group received`}>
                <AgentImage src={companyLogo} alt={"F"} showAvatar={true} />
                <div className={`message__group--content`}>
                    <div className={`message__content received`}>
                        <div className='message'>
                            Hello! Welcome to {teamName}, how may we help you
                            today?
                        </div>
                    </div>
                </div>
            </div>

            <div className='options__container'>
                <div className='options__group'>
                    {pinnedConversations?.map(
                        ({ issue, conversationId }, key) => (
                            <div
                                className={`branch__option
                    `}
                                onClick={() =>
                                    routeToChat("", "", conversationId)
                                }
                                key={key}>
                                <span>{issue?.issueName}</span>
                            </div>
                        )
                    )}

                    <div
                        className={`branch__option
                        in-app-chat-interface--something-else`}
                        onClick={
                            () => routeToChat("", "")
                            // hasWebHookEnabled
                            //     ? routeToChat("", "")
                            //     : handleInitialRequestUpdate(ASK__SUPPORT)
                        }>
                        <span className={""}>it is something else</span>
                    </div>

                    <div
                        className={`branch__option
                        in-app-chat-interface--something-else is-existing`}
                        onClick={() =>
                            handleInitialRequestUpdate(OPEN_OLD_CONVERSATIONS)
                        }>
                        <span className={""}>continue an existing ticket</span>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default InAppChatInterfaceMessage;
