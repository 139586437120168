import ChatSettingsToggler from "components/Chat/ChatModule/ChatHeader/ChatSettingsToggler/ChatSettingsToggler";
import ImageView from "components/common/ImageView/ImageView";
import { useSelector } from "react-redux";

const InAppChatInterfaceHeader = () => {
    const {
        chatSettings: { companyLogo, teamName },
    } = useSelector((state) => state.chat);

    // const src =
    //     "https://metacare-public-assests-repository.s3.eu-west-1.amazonaws.com/agents-inbox/4107015033/b5592408-fad9-4553-b385-c0394cb9fb28.png";

    return (
        <section className='in-app-chat-interface__header'>
            <ImageView
                src={companyLogo}
                // alt={teamName}
                layout='fill'
                className='company__logo'
            />{" "}
            <span className='workspace__agent__name'>{teamName}</span>
            <div className={`in-app-chat-interface__header__toggle`}>
                <ChatSettingsToggler isMobile={true} />
            </div>
        </section>
    );
};

export default InAppChatInterfaceHeader;
