import { AgentImage } from "components/ui";
import SkeletonLoader from "components/ui/SkeletonLoader/SkeletonLoader";
import "./InAppChatInterfaceLoader.scss";

const InAppChatInterfaceLoader = () => {
    return (
        <section className='in-app-chat-loader'>
            <div className={`message__group`}>
                <AgentImage src={"companyLogo"} alt={"F"} showAvatar={true} />
                <div className={`message__group--content`}>
                    <div className='message'>
                        <SkeletonLoader counts={1} />
                    </div>
                </div>
            </div>

            <div className='options__container'>
                <div className='options__group'>
                    {[0, 1, 2, 3, 4]?.map(({ issue }, key) => (
                        <div
                            className={`option__loader
                    `}
                            key={key}>
                            <SkeletonLoader counts={1} />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default InAppChatInterfaceLoader;
