import Empty from "components/common/Empty/Empty";
import { DotLoader } from "components/ui";
import { dataQueryStatus } from "utils/formatHandlers";
import NewTicketButton from "../CustomerTicketsContainer/CustomerTickets/common/NewTicketButton/NewTicketButton";

const { LOADING } = dataQueryStatus;

const ChatEmptyInterface = ({ loading, status, createNewTicket }) => {
    return (
        <>
            <div className='empty__chat--interface'>
                <div className='empty__group'>
                    {loading || status === LOADING ? (
                        <>
                            <DotLoader />
                            <p className='preparing__tickets'>
                                Preparing your tickets
                            </p>
                        </>
                    ) : (
                        <>
                            <Empty
                                message={
                                    loading || status === LOADING
                                        ? `Please wait while we are retrieving your conversations`
                                        : `No conversation opened yet.`
                                }
                            />
                            <div className='d-md-none w-100'>
                                <div className='row justify-content-center'>
                                    <div className='col-md-10'>
                                        <NewTicketButton
                                            handleClick={createNewTicket}
                                            otherClassNames={"large"}
                                            loading={
                                                loading || status === LOADING
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default ChatEmptyInterface;
