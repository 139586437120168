import { scrollChatToBottom } from "utils/helper";
import { appMessageUserTypes } from "components/Chat/ChatModule/LiveChat/MessageBody/Messages/enums";
import "./NewMessagesIndicator.scss";

const { WORKSPACE_AGENT } = appMessageUserTypes;

const NewMessagesIndicator = ({
    setHasNewUnreadMessage,
    hasNewUnreadMessage,
    messages,
    ticketId,
}) => {
    const scrollUserToLastMessage = () => {
        scrollChatToBottom();
        setHasNewUnreadMessage(false);
    };

    const unreadMessages =
        messages
            ?.filter(
                (item) =>
                    item?.ticketId === ticketId &&
                    item?.senderType === WORKSPACE_AGENT
            )
            .filter((item) => item?.isRead === false)?.length || 0;

    return (
        <>
            {hasNewUnreadMessage && (
                <div
                    className='new-message-indicator'
                    onClick={() => scrollUserToLastMessage()}>
                    <span>{unreadMessages} new messages</span>
                </div>
            )}
        </>
    );
};

export default NewMessagesIndicator;
