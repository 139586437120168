import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import FadeIn from "components/ui/FadeIn";
import API from "lib/api";
import apiRoutes from "lib/api/apiRoutes";
import { getErrorMessage, validateEmail } from "utils/helper";
import { VERIFY_USER_ACTIONS } from "components/Chat/enums";
import { dataQueryStatus } from "utils/formatHandlers";
import { useHistory } from "react-router-dom";
import { defaultTemplates } from "hoc/AppTemplateWrapper/enum";
import { useWindowSize } from "utils/hooks";
import "./CustomerVerification.scss";
import CustomerVerificationContainer from "./CustomerVerificationContainer/CustomerVerificationContainer";

export const verifystages = {
    initial: "INPUT_EMAIL",
    final: "INPUT_OTP",
    success: "SUCCESS",
};

const { RELAXED } = defaultTemplates;
const { ERROR, DATAMODE, LOADING } = dataQueryStatus;

const CustomerVerification = ({
    customer,
    handleVerifyAction,
    messages,
    verifyUserAction,
    ticketId,
}) => {
    const isSaveConvoAction =
        verifyUserAction === VERIFY_USER_ACTIONS.SAVE_CONVERSATION;
    const { initial, final, success } = verifystages;

    const {
        user: { email, userId },
    } = useSelector((state) => state.auth);
    const { defaultTemplate } = useSelector(
        (state) => state?.chat?.chatSettings
    );

    const history = useHistory();

    const isLinkEmail =
        history?.location?.pathname === "/conversation" ||
        validateEmail(email || userId);

    const [verifyStage, setVerifyStage] = useState(
        !isLinkEmail ? initial : final
    );
    const [initialStepRequest, setinitialStepRequest] = useState();
    const [status, setStatus] = useState(!isLinkEmail ? DATAMODE : LOADING);
    const [errorMssg, setErrorMssg] = useState("");
    const [displayBannerMessage, toggleBannerMessage] = useState(true);

    const { width } = useWindowSize();

    const isRelaxedTemplate = defaultTemplate === RELAXED;
    const isNotTablet = width > 768;
    const showBannerMessage =
        isRelaxedTemplate &&
        displayBannerMessage &&
        verifyStage !== success &&
        isNotTablet;

    const handleEmailRequestUpdate = (data) => {
        setinitialStepRequest(data);
        setVerifyStage(verifystages.final);
    };

    const linkEmail = async () => {
        try {
            setStatus(LOADING);
            const url = apiRoutes.linkEmail;
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res.data;
                setinitialStepRequest({ sessionId: data });
                setStatus(DATAMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        if (isLinkEmail) {
            linkEmail();
        }
        //eslint-disable-next-line
    }, []);

    return (
        <FadeIn location={verifyStage} className='big-container'>
            <div className='customer-verification signin'>
                {!isSaveConvoAction && !isLinkEmail && (
                    <div
                        className='customer-verify__icon'
                        onClick={() => handleVerifyAction()}>
                        <ReactSVG
                            src={imageLinks?.svg?.cancel}
                            className='verify-icon'
                        />
                    </div>
                )}

                <CustomerVerificationContainer
                    {...{
                        toggleBannerMessage,
                        showBannerMessage,
                        status,
                        customer,
                        handleEmailRequestUpdate,
                        isSaveConvoAction,
                        verifyStage,
                        initialStepRequest,
                        email,
                        userId,
                        handleVerifyAction,
                        ticketId,
                        setVerifyStage,
                        isLinkEmail,
                        messages,
                        errorMssg,
                        linkEmail,
                    }}
                />
            </div>
        </FadeIn>
    );
};

export default CustomerVerification;
