export const signInstages = {
    initial: "WELCOME_PAGE",
    email_stage: "INPUT_EMAIL",
    final: "INPUT_OTP",
};

export const emailFormActions = {
    ADD_EMAIL: "ADD_EMAIL",
    ADD_NAME: "ADD_NAME",
};

export const inAppAuthActions = {
    ASK__SUPPORT: "ASK__SUPPORT",
    OPEN_OLD_CONVERSATIONS: " OPEN_OLD_CONVERSATIONS",
};
