import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import UploadPreviewError from "../../UploadPreview/UploadPreviewError/UploadPreviewError";
import UploadedFileIcon from "./UploadedFileIcon/UploadedFileIcon";
import ModalPreview from "components/Chat/ChatModule/LiveChat/ModalPreview/ModalPreview";
import { useState } from "react";

const UploadedFile = ({
    handleRetry,
    file,
    status,
    handleRemoveFile,
    fileIndex,
    icon,
}) => {
    const { fileAttachmentName, uploadStatus } = file;
    const [showModal, toggleModal] = useState(false);

    return (
        <>
            <div className='uploaded-file'>
                <div onClick={() => toggleModal(true)}>
                    <ReactSVG src={icon ? icon : imageLinks?.svg?.attachment} />
                    <span>{fileAttachmentName}</span>
                </div>

                <UploadedFileIcon
                    {...{
                        fileAttachmentName,
                        fileIndex,
                        handleRemoveFile,
                    }}
                    status={status || uploadStatus}
                />

                <UploadPreviewError
                    status={status || uploadStatus}
                    file={file}
                    handleRemoveFile={handleRemoveFile}
                    handleRetry={handleRetry}
                    fileIndex={fileIndex}
                    uploadStatus={uploadStatus}
                />
            </div>

            {showModal && (
                <ModalPreview
                    showModal={showModal}
                    toggleModal={() => toggleModal(false)}
                    preview={file?.fileAttachmentUrl}
                    previewType={file?.fileAttachmentType}
                    fileName={file?.fileAttachmentName}
                />
            )}
        </>
    );
};

export default UploadedFile;
