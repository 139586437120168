import ImageView from "components/common/ImageView/ImageView";
import React from "react";
import { useDispatch } from "react-redux";
import { setActiveTicket } from "store/tickets/actions";

const CompanyChatLogo = ({ src, alt, className, name }) => {
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(setActiveTicket());
    };
    return (
        <>
            <div onClick={handleClick} className="logo">
                <div className={className}>
                    <ImageView src={src} alt={alt} layout='fill' />
                </div>{" "}
                {name && <span className='workspace__agent__name'>{name}</span>}
            </div>
        </>
    );
};

export default CompanyChatLogo;
