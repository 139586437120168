import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import API from "../../lib/api";
import apiRoutes from "../../lib/api/apiRoutes";
import {
    getDevicePushToken,
    onMessageListener,
} from "../../lib/firebase/firebase";
import {
    reconnectSocket,
    socket,
    SocketContext,
} from "../../lib/socket/context/socket";
import { retriveAccessToken, setConvoEventTriggerStatus } from "storage/sessionStorage";
import { setActiveTicket } from "../../store/tickets/actions";
import { dataQueryStatus } from "../../utils/formatHandlers";
import {
    extractParamsFromUrl,
    getErrorMessage,
    getUserAuthCredentials,
    ticketRouteChecker,
} from "../../utils/helper";
import { ToastContext } from "../common/Toast/context/ToastContextProvider";
import queryString from "query-string";
import ChatHeader from "./ChatModule/ChatHeader/ChatHeader";
import ChatModule from "./ChatModule/ChatModule";
import ChatToastNotification from "./ChatToastNotification/ChatToastNotification";
import { pushAuthUser } from "store/auth/actions";
import TicketCloseModal from "./TicketCloseModal/TicketCloseModal";
import { defaultThemes } from "hoc/AppTemplateWrapper/enum";
import { useWindowSize } from "utils/hooks";
import pushToDashboard from "components/SignInForm/actions";
import { storeUserAuth } from "storage/sessionStorage";
import { isLiveApp } from "config/config";
import "./Chat.scss";
import ChatEmptyInterface from "./ChatEmptyInterface/ChatEmptyInterface";

const { ERROR, LOADING, DATAMODE, NULLMODE } = dataQueryStatus;
const { DARK_MODE_DEFAULT } = defaultThemes;

const Chat = () => {
    const [showChatMenu, toggleChatMenu] = useState(false);
    const [showTictketActionModal, toggleTicketActionModal] = useState();
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMssg, setErrorMssg] = useState("");

    const dispatch = useDispatch();
    const history = useHistory();

    const { width } = useWindowSize();

    const toastMessage = useContext(ToastContext);

    const { activeTicket } = useSelector((state) => state.tickets);

    const { user } = useSelector((state) => state.auth);

    const { location } = history;

    const { state } = location || {};

    const selectedTicket = activeTicket;
    const [customerTickets, setCustomerTickets] = useState([]);

    const [showVerifyForm, setShowVerifyForm] = useState(false);
    const [verifyUserAction, setVerifyUserAction] = useState();

    const [socketConnection, setSocketConnection] = useState(socket);

    const isTicketRoutedLink = ticketRouteChecker();

    const { firstName, lastName, email } = extractParamsFromUrl();

    const { conversationId, appUserId } = getUserAuthCredentials(
        state,
        user,
        // queryParams
    );

    const userToken = retriveAccessToken();

    const { defaultTheme, workspaceId, workspaceSlug, hasWebHookEnabled } =
        useSelector((state) => state.chat.chatSettings);

    const isDarkModeTheme = defaultTheme === DARK_MODE_DEFAULT;
    const isTablet = width <= 768;

    const getCustomerFromTicketLink = async (httpRequest) => {
        try {
            setStatus(LOADING);
            setErrorMssg();
            const queryParams = queryString.parse(window.location.search);
            const ticketId = queryParams?.ticketId;
            const linkUserId = queryParams?.appUserId;

            const deviceToken = await getDevicePushToken();

            const res = await API.post(
                apiRoutes.validateTicketUser,
                {
                    ticketId,
                    workspaceId,
                    appUserId: linkUserId,
                    deviceToken,
                },
                {
                    signal: httpRequest?.signal,
                }
            );

            if (res.status === 201) {
                const { data } = res.data;
                const { ticket: userTicket } = data;
                dispatch(setActiveTicket(userTicket));
                pushToDashboard(data);
                setStatus(DATAMODE);
                getCustomerTickets(userTicket?.ticketId);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
            window.stop();
        }
    };

    const getCustomerTickets = async (
        ticketId,
        openNewTicket = true,
        openChatMenu = false,
        httpRequest
    ) => {
        try {
            setStatus(LOADING);
            setErrorMssg();
            setLoading(true);
            const res = await API.get(apiRoutes.userTickets, {
                signal: httpRequest?.signal,
            });
            if (res.status === 200) {
                const tickets = res.data.data;
                if (tickets.length > 0) {
                    setCustomerTickets(tickets);

                    const { ticketId: prevSelectedId } = activeTicket || {};

                    let selectedTicket = tickets?.find(
                        (x) => x.ticketId === ticketId
                    );
                    let prevSelectedTicket = tickets?.find(
                        (x) => x.ticketId === prevSelectedId
                    );

                    const newTicket = selectedTicket
                        ? selectedTicket
                        : prevSelectedTicket
                            ? prevSelectedTicket
                            : tickets[0];

                    dispatch(
                        setActiveTicket({
                            ...newTicket,
                            activeConvoSuggestion: false,
                        })
                    );

                    const { customer } = newTicket || {};

                    if (customer) {
                        dispatch(pushAuthUser(customer));
                        storeUserAuth(customer);
                    }

                    setStatus(DATAMODE);
                    setLoading(false);
                    toggleChatMenu(openChatMenu ? true : false);
                } else {
                    if (openNewTicket) {
                        createNewTicket();
                    } else {
                        setStatus(NULLMODE);
                        setLoading(false);
                    }
                }
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
            window.stop();
        }
    };

    const handleTicketModalAction = () => {
        toggleTicketActionModal(true);
    };

    const handleTicketCloseSuccess = () => {
        dispatch(setActiveTicket());

        getCustomerTickets(null, false, isTablet ? true : false);
        toggleTicketActionModal(false);
    };

    const createNewTicket = async () => {
        try {
            setStatus(LOADING);
            setErrorMssg();
            const url = apiRoutes?.createTicket;
            const res = await API.post(url, {
                userToken: retriveAccessToken(),
            });
            if (res.status === 201) {
                const { ticketId } = res.data.data;
                getCustomerTickets(ticketId);
                setConvoEventTriggerStatus(false)
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const validateUser = async (httpRequest) => {
        try {
            setStatus(LOADING);
            setErrorMssg();

            const url = apiRoutes?.validateUser;
            const deviceToken = await getDevicePushToken();
            const res = await API.post(
                url,
                {
                    workspaceId,
                    appUserId,
                    firstName,
                    lastName,
                    email,
                    deviceToken,
                },
                {
                    signal: httpRequest?.signal,
                }
            );

            if (res.status === 201) {
                const { data } = res.data;

                pushToDashboard(data);

                if (conversationId) {
                    engageConversation();
                } else {
                    getCustomerTickets(null, true, false,)
                    // const url = isLiveApp
                    //     ? "/chat"
                    //     : `/chat?workspaceSlug=${workspaceSlug}`;
                    // history.push(url);
                }
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const engageConversation = async (httpRequest) => {
        try {
            setStatus(LOADING);
            setErrorMssg();
            const url = apiRoutes?.engageConversation(conversationId);
            const res = await API.get(url, {
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data;
                const url = isLiveApp
                    ? "/chat"
                    : `/chat?workspaceSlug=${workspaceSlug}`;

                dispatch(
                    setActiveTicket({
                        ...data,
                    })
                );

                if (
                    history?.location?.pathname !== "/conversation" &&
                    !history?.location?.pathname?.includes("chat")
                ) {
                    history.push(url);
                }

                if (hasWebHookEnabled) {
                    getCustomerTickets();
                } else {
                    getCustomerTickets();
                }

                // setStatus(DATAMODE);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleTicketSelect = (ticket) => {
        dispatch(setActiveTicket(ticket));
    };

    const toastNotification = ({ title, body }) => {
        toastMessage(<ChatToastNotification {...{ title, body }} />);
    };

    onMessageListener().then((payload) => {
        const { notification } = payload;
        toastNotification(notification);
    });

    const handleVerifyAction = (verifyAction) => {
        setShowVerifyForm(!showVerifyForm);
        setVerifyUserAction(verifyAction);
    };

    const callHandler = (httpRequest) => {
        conversationId
            ? engageConversation(httpRequest)
            : isTicketRoutedLink
                ? getCustomerFromTicketLink(httpRequest)
                : getCustomerTickets(null, true, false, httpRequest);
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        if (
            (userToken === undefined || userToken === null) &&
            !isTicketRoutedLink &&
            appUserId &&
            workspaceId
        ) {
            validateUser(httpRequest);
        } else {
            callHandler(httpRequest);
        }

        return () => {
            httpRequest.abort();
        };
        //eslint-disable-next-line
    }, [isTicketRoutedLink, workspaceId,]);

    const handleCloseTicket = () => {
        toggleTicketActionModal(true);
    };

    const reconnectUser = () => {
        const socketReconnection = reconnectSocket(userToken);
        setSocketConnection(socketReconnection);
    };

    useEffect(() => {
        if (!socketConnection?.connected) {
            reconnectUser();
        }
        //eslint-disable-next-line
    }, [socket, userToken]);

    return (
        <>
            <SocketContext.Provider value={socketConnection}>
                <div
                    className={`row justify-content-center h-100 ${isDarkModeTheme ? "dark__desktop" : ""
                        }`}>
                    <div className='col-md-10 col-12'>
                        <div className='chat__container'>
                            <ChatHeader
                                {...{
                                    status,
                                    errorMssg,
                                    handleTicketSelect,
                                    customerTickets,
                                    selectedTicket,
                                    createNewTicket,
                                    getCustomerTickets,
                                    handleTicketModalAction,
                                    showVerifyForm,
                                    handleCloseTicket,
                                    showChatMenu,
                                    toggleChatMenu,
                                    handleVerifyAction,
                                }}
                                showActions={
                                    loading || showVerifyForm
                                        ? false
                                        : !showChatMenu
                                            ? true
                                            : selectedTicket?.ticketId === undefined
                                                ? true
                                                : false
                                }
                            />
                            {selectedTicket?.ticketId ? (
                                <ChatModule
                                    key={selectedTicket?.ticketId}
                                    ticket={selectedTicket}
                                    getCustomerTickets={getCustomerTickets}
                                    showVerifyForm={showVerifyForm}
                                    handleVerifyAction={handleVerifyAction}
                                    handleCloseTicket={handleCloseTicket}
                                    handleTicketCloseSuccess={
                                        handleTicketCloseSuccess
                                    }
                                    handleOpenNewTicket={createNewTicket}
                                    reconnectUser={reconnectUser}
                                    verifyUserAction={verifyUserAction}
                                />
                            ) : (
                                <ChatEmptyInterface
                                    {...{
                                        loading,
                                        status,
                                        createNewTicket,
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {showTictketActionModal && (
                    <TicketCloseModal
                        showModal={showTictketActionModal}
                        closeModal={() => toggleTicketActionModal(false)}
                        referenceData={selectedTicket}
                        handleSuccess={handleTicketCloseSuccess}
                        handleTicketCloseSuccess={handleTicketCloseSuccess}
                    />
                )}
            </SocketContext.Provider>
        </>
    );
};

export default Chat;
