import { Textarea } from "components/ui";
import { getCurrentFormInputRules, getNumberPrefix } from "utils/helper";
import DatePickerWrapper from "../DatePickerWrapper/DatePickerWrapper";
import RelaxedSelectUI from "components/ui/InputTypes/SelectUI/RelaxedSelectUI/RelaxedSelectUI";
import SelectUI from "components/ui/InputTypes/SelectUI/SelectUI";
import { formInputTypes } from "../../MessageBody/Messages/enums";

const { TEXT, NUMERIC, LONG_TEXT, DATE, MULTISELECT, IMAGE } = formInputTypes;

const LiveChatInputTypes = ({
    currentFormElement,
    inputType,
    datePickerStage,
    request,
    updateRequest,
    disableInput,
    setDatePickerStage,
    loading,
    isRelaxedTemplate,
    handleTyping,
    handleInputBlur,
    handleInputFocus,
    inputRef,
    isDisabled,
    onEnterPress
}) => {
    
    const renderBasedOnInputType = () => {
        const { formElementPlaceholder, formElementOptions, options, rules } =
            currentFormElement || {};

        const { maxLength, minLength, isEmail, isLink, max } =
            getCurrentFormInputRules(rules, inputType);

        const numberPrefix = getNumberPrefix(rules);

        const textInputType = isEmail
            ? "email"
            : isLink
            ? "url"
            : inputType === NUMERIC
            ? "number"
            : "text";

        switch (inputType) {
            case TEXT:
            case NUMERIC:
            case LONG_TEXT:
                return (
                    <Textarea
                        placeholder={
                            formElementPlaceholder
                                ? formElementPlaceholder
                                : "Type message here"
                        }
                        inputClass='w-100 border-0'
                        value={request?.message}
                        onChange={handleTyping}
                        grpClass='w-100'
                        label='Chat'
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        hideLabel={true}
                        ref={inputRef}
                        maxLength={maxLength?.ruleConstraint}
                        minLength={minLength?.ruleConstraint}
                        type={textInputType}
                        id='form__input'
                        // pattern={
                        //     isLink?.ruleConstraint &&
                        //     `${isLink?.ruleConstraint}/.*`
                        // }
                        // pattern={pattern}
                        // pattern={`/^$|(http(s)?:\/\/.)?(\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g`}
                        max={max?.ruleConstraint}
                        disabled={
                            isDisabled || inputType === IMAGE || disableInput
                        }
                        showPrefix={inputType === NUMERIC}
                        prefix={inputType === NUMERIC ? numberPrefix : ""}
                        onKeyDown={onEnterPress}
                    />
                );

            case DATE:
                return (
                    <>
                        <DatePickerWrapper
                            stage={datePickerStage}
                            request={request}
                            updateRequest={updateRequest}
                            setDatePickerStage={setDatePickerStage}
                            disabled={disableInput}
                            loading={loading}
                            hasBtnActions={!isRelaxedTemplate}
                        />
                    </>
                );

            case MULTISELECT:
                const usedArr = options ? options : formElementOptions;
                const selectOptions = usedArr?.map((item) => ({
                    label: item,
                    value: item,
                }));
                return (
                    <>
                        {isRelaxedTemplate ? (
                            <RelaxedSelectUI
                                options={selectOptions}
                                handleChange={(value) =>
                                    updateRequest({
                                        ...request,
                                        message: value,
                                    })
                                }
                            />
                        ) : (
                            <SelectUI
                                options={selectOptions}
                                handleChange={(value) =>
                                    updateRequest({
                                        ...request,
                                        message: value,
                                    })
                                }
                                onFocus={handleInputFocus}
                                onBlur={handleInputBlur}
                                isDisabled={disableInput}
                            />
                        )}
                    </>
                );

            default:
                return (
                    <Textarea
                        placeholder='Type message here'
                        inputClass='w-100 border-0'
                        value={request?.message}
                        onChange={handleTyping}
                        grpClass='w-100'
                        label='Chat'
                        ref={inputRef}
                        hideLabel={true}
                        id='form__input'
                        disabled={
                            isDisabled || inputType === IMAGE || disableInput
                        }
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        maxLength={maxLength?.ruleConstraint}
                        minLength={minLength?.ruleConstraint}
                        // pattern={pattern}
                        max={max?.ruleConstraint}
                        onKeyDown={onEnterPress}
                    />
                );
        }
    };
    return <>{renderBasedOnInputType()}</>;
};

export default LiveChatInputTypes;
