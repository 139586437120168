import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Info } from "components/ui";
import BraillePatternDots from "./BraillePatternDots/BraillePatternDots";
import TogglerDropdown from "./TogglerDropdown/TogglerDropdown";
import TogglerModal from "./TogglerModal/TogglerModal";
import { defaultTemplates, defaultThemes } from "hoc/AppTemplateWrapper/enum";
import { changeTheme } from "store/chat/actions";
import { useWindowSize } from "utils/hooks";

const { WORKMODE } = defaultTemplates;
const { DARK_MODE_DEFAULT, WHITE_MODE_DEFAULT } = defaultThemes;

const ChatSettingsToggler = ({
    isMobile,
    handleCloseTicket,
    canCloseTicket,
}) => {
    const [showModal, toggleModal] = useState(false);
    const { defaultTemplate, defaultTheme } = useSelector(
        (state) => state.chat.chatSettings
    );
    const { width } = useWindowSize();
    const dispatch = useDispatch();

    const handleToggleModal = () => toggleModal(!showModal);

    const isWorkModeTemplate = defaultTemplate === WORKMODE;
    const isDarkModeTheme = defaultTheme === DARK_MODE_DEFAULT;
    const isNotTablet = width > 768;

    const handleChangeTheme = () => {
        const theme = isDarkModeTheme ? WHITE_MODE_DEFAULT : DARK_MODE_DEFAULT;

        dispatch(changeTheme(theme));
    };

    return (
        <>
            {isWorkModeTemplate || isNotTablet ? (
                <TogglerDropdown
                    isMobile={isMobile}
                    handleCloseTicket={handleCloseTicket}
                    handleChangeTheme={handleChangeTheme}
                    isWorkModeTemplate={isWorkModeTemplate}
                    isDarkModeTheme={isDarkModeTheme}
                    canCloseTicket={canCloseTicket}
                />
            ) : (
                <Info
                    otherClass={"ticket-header__icon"}
                    onClick={handleToggleModal}>
                    <BraillePatternDots
                        onClick={handleToggleModal}
                        isMobile={isMobile}
                    />
                </Info>
            )}

            {showModal && (
                <TogglerModal
                    showModal={showModal}
                    toggleModal={handleToggleModal}
                    handleChangeTheme={handleChangeTheme}
                    handleCloseTicket={handleCloseTicket}
                    isDarkModeTheme={isDarkModeTheme}
                    canCloseTicket={canCloseTicket}
                />
            )}
        </>
    );
};

export default ChatSettingsToggler;
