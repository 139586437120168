export const RECEIVE_MESSAGE = "receive-message";
export const SEND_CUSTOMER_MESSAGE = "send-message";
export const SEND_BRANCH_OPTION = "send_branch_option";
export const RECEIVE_ALL_MESSAGE = "receive-all-message";
export const SEND_AGENT_REPLY = "send_agent_reply";
export const SEND_AGENT_CONVERSATION_REPLY = "send_agent_conversation_reply";
export const FILL_FORM_RECORD = "fill_form_record";
export const SUBSCRIBE_TO_TICKET = "subscribe-to-ticket";
export const IS_TYPING = "is_typing";
export const IS_NOT_TYPING = "is_not_typing";
export const SEND_AGENT_COLLECTION = "send_agent_collection";
export const NEW_TEST_TICKET = "new_ticket_test";
export const TICKET_PHASE_CHANGE = "ticket_phase_change";
export const SEND_CUSTOMER_CONVERSATION_REPLY =
    "send_customer_conversation_reply";
export const CLOSED_TICKET = "closed_ticket";
export const NEW_TICKET_UPDATE = "new_ticket_update";
export const MARK_AS_READ = "mark_as_read";
export const AGENT_IS_UNAVAILABLE = "agent_unavailable";
export const SEND_AGENT_TICKET = "assign_ticket_to_agent";
