import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AgentImage } from "components/ui";
import MessageOptions from "./MessageOptions/MessageOptions";
import MessageContent from "./MessageContent/MessageContent";
import MessageTimeStatus from "./MessageTimeStatus/MessageTimeStatus";
import { messageTypes, appMessageUserTypes } from "../enums";
import MessageAttachments from "./MessageAttachments/MessageAttachments";
import { defaultTemplates } from "hoc/AppTemplateWrapper/enum";
import { INPUT_NEEDED } from "../enums";
import { shouldDisplayTimeStatus, isLastInView } from "utils/helper";
import { updateTicketMessageStatus } from "store/tickets/actions";
import "./Message.scss";

const { WORKMODE, RELAXED } = defaultTemplates;
const {
    BRANCH_SUB_SENTENCE,
    FORM_REQUEST,
    DOWNTIME_BRANCH_SUB_SENTENCE,
    UPTIME_BRANCH_SUB_SENTENCE,
} = messageTypes;
const { WORKSPACE_AGENT } = appMessageUserTypes;

const Message = ({
    data,
    agent,
    handleMessageOptionSelect,
    handleOptConversation,
    messageIndex,
    messagesDepth,
    openPreviewModal,
    messages,
    setActiveConvo,
    requestAllMessages,
    handleNewMessage,
    status,
    mssgSendStatus,
}) => {
    const { defaultTemplate } = useSelector(
        (state) => state?.chat?.chatSettings
    );
    const lastMessage = messages[messages.length - 1];
    const immediatePreviousMessage = messages[messages.length - 2];

    const {
        senderType,
        messageType,
        branchOptions,
        messageContent,
        messageId,
        ticketId,
        selectedOption,
        fileAttachments,
        readDate,
        deliveryDate,
        isRead,
    } = data || {};

    

    const parsedBranchOptions = typeof branchOptions === `string` ? JSON.parse(branchOptions.replace(/\\/g, '')) : branchOptions;

    const { displayPicture, firstName } = agent || {};

    const isReceivedMessage = senderType === WORKSPACE_AGENT;
    const hasAttachment = fileAttachments?.length > 0;

    const messageCopy = messages;
    const recentAdminMessage = [...messageCopy]
        .reverse()
        ?.find(
            (message) =>
                message?.messageActionType !== INPUT_NEEDED &&
                message?.senderType === WORKSPACE_AGENT
        );
    const showMessageOptions =
        (lastMessage?.messageActionType === INPUT_NEEDED &&
            recentAdminMessage?.messageId === messageId &&
            immediatePreviousMessage?.messageType !== FORM_REQUEST) ||
        messageIndex === messagesDepth;

    const isRelaxedTemplate = defaultTemplate === RELAXED;
    const isWorkModeTemplate = defaultTemplate === WORKMODE;

    const removeMargin =
        messageType === BRANCH_SUB_SENTENCE ||
        messageType === UPTIME_BRANCH_SUB_SENTENCE ||
        messageType === DOWNTIME_BRANCH_SUB_SENTENCE;

    const displayTimeStatus = shouldDisplayTimeStatus(
        messages,
        data,
        messageIndex - 1
    );

    const showAvatar =
        messageType === BRANCH_SUB_SENTENCE ? false : displayTimeStatus;

    const dispatch = useDispatch();

    const handleMarkAsRead = () => {
        if (isLastInView(false, `A${messageId}`) && isRead === false) {
            dispatch(
                updateTicketMessageStatus({ ticketId, messageId, isRead: true })
            );
        }
    };

    useEffect(() => {
        const messageBody = document.querySelector(".message-body");

        messageBody.addEventListener("scroll", handleMarkAsRead);

        return () =>
            messageBody.removeEventListener("scroll", handleMarkAsRead);
        // eslint-disable-next-line
    }, [messages.length]);

    return (
        <div
            id={`A${messageId}` ? `A${messageId}` : ""}
            className={`message__group ${
                isReceivedMessage ? "received" : "sent"
            } ${removeMargin ? "remove__margin" : ""}`}>
            {isReceivedMessage && isWorkModeTemplate && (
                <>
                    <AgentImage
                        src={displayPicture}
                        alt={firstName}
                        showAvatar={showAvatar}
                    />
                </>
            )}

            <div className={`message__group--content `} id='message__content'>
                {hasAttachment && (
                    <MessageAttachments
                        fileAttachments={fileAttachments}
                        openPreviewModal={openPreviewModal}
                        isReceivedMessage={isReceivedMessage}
                    />
                )}
                <MessageContent
                    isReceivedMessage={isReceivedMessage}
                    messageContent={messageContent}
                    fileAttachments={fileAttachments}
                    openPreviewModal={openPreviewModal}
                    messageType={messageType}
                    setActiveConvo={setActiveConvo}
                    requestAllMessages={requestAllMessages}
                    messageId={messageId}
                    messages={messages}
                    lastMessage={lastMessage}
                    data={data}
                    handleNewMessage={handleNewMessage}
                    messageIndex={messageIndex}
                />
                {parsedBranchOptions?.length > 0 && isRelaxedTemplate ? (
                    showMessageOptions ? (
                        <MessageOptions
                            selectedOption={selectedOption}
                            options={parsedBranchOptions}
                            messageIndex={messageIndex}
                            messagesDepth={messagesDepth}
                            messageType={messageType}
                            handleMessageOptionSelect={
                                handleMessageOptionSelect
                            }
                            handleOptConversation={handleOptConversation}
                            deliveryDate={deliveryDate}
                            lastMessage={lastMessage}
                            status={status}
                            mssgSendStatus={mssgSendStatus}
                        />
                    ) : (
                        <></>
                    )
                ) : (
                    isWorkModeTemplate && (
                        <MessageOptions
                            selectedOption={selectedOption}
                            options={parsedBranchOptions}
                            messageIndex={messageIndex}
                            messagesDepth={messagesDepth}
                            messageType={messageType}
                            handleMessageOptionSelect={
                                handleMessageOptionSelect
                            }
                            handleOptConversation={handleOptConversation}
                            deliveryDate={deliveryDate}
                            lastMessage={lastMessage}
                            status={status}
                            mssgSendStatus={mssgSendStatus}
                        />
                    )
                )}
                {messageType !== BRANCH_SUB_SENTENCE &&
                    !isRelaxedTemplate &&
                    displayTimeStatus && (
                        <>
                            <MessageTimeStatus
                                date={
                                    isReceivedMessage ? readDate : deliveryDate
                                }
                            />
                        </>
                    )}
            </div>
        </div>
    );
};

export default Message;
