import "./InAppChatInterface.scss";
import InAppChatInterfaceMessage from "./InAppChatInterfaceMessage/InAppChatInterfaceMessage";
import InAppChatInterfaceHeader from "./InAppChatInterfaceHeader/InAppChatInterfaceHeader";
import PoweredBy from "components/common/PoweredBy/PoweredBy";
import {
    getStoredPinnedConversations,
    storePinnedConversations,
} from "storage/localStorage";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import apiRoutes from "lib/api/apiRoutes";
import { dataQueryStatus } from "utils/formatHandlers";
import API from "lib/api";
import { getErrorMessage } from "utils/helper";

import ErrorView from "components/common/ErrorView/ErrorView";
import InAppChatInterfaceLoader from "./InAppChatInterfaceLoader/InAppChatInterfaceLoader";

const { LOADING, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

export const InAppPinnedConversationSuggestions = ({ handleInitialRequestUpdate, routeToChat }) => {
    const storedPinnedConversations = getStoredPinnedConversations() || [];
    const [pinnedConversations, setPinnedConversations] = useState(
        storedPinnedConversations || []
    );
    const [status, setStatus] = useState(
        storedPinnedConversations?.length > 0 ? DATAMODE : ""
    );
    const [errorMssg, setErrorMssg] = useState("");
    const { workspaceId } = useSelector((state) => state.chat.chatSettings);

    const getPinnedConversations = async () => {
        try {
            if (storedPinnedConversations?.length === 0) {
                setStatus(LOADING);
            }
            const url = apiRoutes.getPinnedConversations;
            const res = await API.get(url, {
                params: {
                    workspaceId,
                },
            });

            if (res.status === 200) {
                const { data } = res.data;

                let newStatus = data?.length > 0 ? DATAMODE : NULLMODE;
                setPinnedConversations(data);
                setStatus(newStatus);
                storePinnedConversations(data);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    useEffect(() => {
        getPinnedConversations();
        //eslint-disable-next-line
    }, []);

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <InAppChatInterfaceLoader />;

            case DATAMODE:
                return (
                    <InAppChatInterfaceMessage
                        handleInitialRequestUpdate={handleInitialRequestUpdate}
                        routeToChat={routeToChat}
                        pinnedConversations={pinnedConversations}
                    />
                );

            case ERROR:
                return (
                    <div className='pinned__conversations__error'>
                        <ErrorView
                            message={errorMssg}
                            retry={() => getPinnedConversations()}
                        />
                    </div>
                );
            default:
                return "";
        }
    };

    return (

        <div className='in-app-chat-interface__body'>
            {renderBasedOnStatus()}
        </div>

    );
};

const InAppChatInterface = ({ handleInitialRequestUpdate, routeToChat }) => {

    return (
        <div className='in-app-chat-interface'>
            <div className='in-app-chat-interface__container'>
                <InAppChatInterfaceHeader />

                <div className='in-app-chat-interface__body'>
                    <InAppPinnedConversationSuggestions {
                        ...{
                            handleInitialRequestUpdate,
                            routeToChat
                        }
                    }/>
                </div>

                <section className='in-app-chat-interface__footer'>
                    <PoweredBy />
                </section>
            </div>
        </div>
    );
};

export default InAppChatInterface;
