import { Button } from "components/ui";
import { dataQueryStatus } from "utils/formatHandlers";

const { LOADING, ERROR } = dataQueryStatus;

const LiveChatInputButton = ({
    hasUploads,
    isDateFormElement,
    isFormElementImage,
    isTablet,
    isRelaxedTemplate,
    isFinalDatePickerStage,
    isFormElementMultiselect,
    disableInput,
    status,
    mssgSendStatus,
    btnDisabled,
    handleDatePickerStage,
    fetchingInputStatus,
    loading,
    showAttachmentButton
}) => {
    return (
        <>
            {((hasUploads && isFormElementImage && !showAttachmentButton) ||
                (isFinalDatePickerStage && isRelaxedTemplate) ||
                isFormElementMultiselect) && (
                <Button
                    type={isDateFormElement ? "button" : "submit"}
                    text={isDateFormElement ? "Save" : "Submit"}
                    classType='primary'
                    otherClass={`chat__input__relaxed__button ${
                        isDateFormElement ? "chat__input__date__button" : ""
                    } ${!btnDisabled ? "active" : ""} ${
                        isFormElementImage && !hasUploads
                            ? "chat__input__relaxed__hide-button"
                            : ""
                    } `}
                    loading={
                        isFinalDatePickerStage
                            ? disableInput
                            : loading && !(mssgSendStatus === ERROR)
                    }
                    disabled={
                        (btnDisabled ||
                            fetchingInputStatus ||
                            status === LOADING) &&
                        !(mssgSendStatus === ERROR)
                    }
                    onClick={isDateFormElement && handleDatePickerStage}
                />
            )}
        </>
    );
};

export default LiveChatInputButton;
