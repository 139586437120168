import imageLinks from "assets/images";
import { useEffect, useState } from "react";
import { isLastMessageInView } from "utils/helper";
import { networkStrengths } from "../../enums";

const { ONLINE, OFFLINE } = networkStrengths;

const LiveChatStatusBarMessage = ({ user, handleRetry, networkStatus }) => {
    const { email } = user || {};
    const [notifyOnline, setNotifyOnline] = useState(false);

    const renderBasedOnNetworkStrength = () => {
        switch (networkStatus) {
            case OFFLINE:
                return (
                    <span className='error__status' onClick={handleRetry}>
                        <img src={imageLinks?.svg.redRetry} alt='Retry Icon' />
                    </span>
                );
            case ONLINE:
                return (
                    <>
                        {notifyOnline ? (
                            <span className='reconnected'>Connected</span>
                        ) : (
                            <>
                                {email && (
                                    <span className='connected'>{email}</span>
                                )}
                            </>
                        )}
                    </>
                );
            default:
                return (
                    <>{email && <span className='connected'>{email}</span>}</>
                );
        }
    };

    useEffect(() => {
        if (networkStatus === ONLINE) {
            setNotifyOnline(true);
            setTimeout(() => {
                setNotifyOnline(false);
            }, 3000);
        }

        // eslint-disable-next-line
    }, [networkStatus]);

    const handleScroll = () => {
        if (isLastMessageInView()) {
            // toggleNotifyNewMessage(false);
        }
    };

    useEffect(() => {
        const messageBody = document.querySelector(".message-body");

        messageBody.addEventListener("scroll", handleScroll);

        return () => messageBody.removeEventListener("scroll", handleScroll);
        // eslint-disable-next-line
    }, []);

    return <>{renderBasedOnNetworkStrength()}</>;
};

export default LiveChatStatusBarMessage;
