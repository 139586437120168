import { ReactSVG } from "react-svg";
import "./MobileScrollNavigator.scss";
import imageLinks from "assets/images";
import { useEffect, useState } from "react";
import { isLastMessageInView, scrollChatToBottom } from "utils/helper";

const MobileScrollNavigator = ({ setHasNewUnreadMessage }) => {
    const [showIndicator, toggleIndicator] = useState(false);

    const handleScroll = () => {
        if (isLastMessageInView()) {
            toggleIndicator(false);
            setHasNewUnreadMessage(false);
        } else {
            toggleIndicator(true);
        }
    };

    const handleScrollToBottom = () => {
        setHasNewUnreadMessage(false);
        scrollChatToBottom();
    };

    useEffect(() => {
        const messageBody = document.querySelector(".message-body");

        messageBody.addEventListener("scroll", handleScroll);

        return () => messageBody.removeEventListener("scroll", handleScroll);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {showIndicator && (
                <div
                    className='mobile-scroll-navigator'
                    onClick={() => handleScrollToBottom()}>
                    <ReactSVG src={imageLinks?.svg.anchorDown} />
                </div>
            )}
        </>
    );
};

export default MobileScrollNavigator;
