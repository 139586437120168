import React, { useState } from "react";
import queryString from "query-string";
import { useSelector } from "react-redux";
import API from "lib/api";
import apiRoutes from "lib/api/apiRoutes";
import { generateRandomId, getErrorMessage } from "utils/helper";
import ValidateForm from "utils/validations/validator";
import { Button, ErrorDialog, Input } from "../../ui";
import { ReactSVG } from "react-svg";
import imageLinks from "assets/images";
import pushToDashboard from "../actions";
import { emailFormActions } from "../enum";
import "./EmailForm.scss";
import { getDevicePushToken } from "lib/firebase/firebase";

const { ADD_EMAIL } = emailFormActions;

const EmailForm = ({
    handleEmailRequestUpdate,
    title,
    subTitle,
    bottomText,
    userId,
    isNameRequest,
}) => {
    const {
        chatSettings: { teamName, workspaceId, },
    } = useSelector((state) => state.chat);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [errorMssg, setErrorMssg] = useState("");

    let params = queryString.parse(window.location.search);
    const conversationId = params?.conversationId;

    const [request, setRequest] = useState({
        conversationId: conversationId,
        email: "",
        workspaceId,
        userId,
        fullname: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const intiateChatForUser = async () => {
        try {
            setErrorMssg("");
            setLoading(true);
            const { fullname, ...requestData } = request;
            const res = await API.post(apiRoutes.authenticate, requestData);

            if (res.status === 201) {
                const { data } = res?.data;
                const { sessionId } = data;
                const { email } = requestData;
                if (sessionId) {
                    handleEmailRequestUpdate({ sessionId, email }, ADD_EMAIL);
                } else {
                    pushToDashboard(data);
                    // const url = isLiveApp
                    //     ? "/chat"
                    //     : `/chat?workspaceSlug=${workspaceSlug}`;
                    // window.location.href = url;
                }
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setLoading(false);
        }
    };

    // const sendUserName = () => {
    //     const { fullname } = request;
    //     handleEmailRequestUpdate({ fullname }, ADD_NAME);
    // };

    const validateUser = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            const { fullname } = request;
            const firstName = fullname.split(" ")[0] || "";
            const lastName = fullname.split(" ")[1] || "";

            const url = apiRoutes?.validateUser;
            const deviceToken = await getDevicePushToken();

            const res = await API.post(url, {
                workspaceId,
                appUserId: generateRandomId(),
                firstName,
                lastName,
                email,
                deviceToken,
            });

            if (res.status === 201) {
                const { data } = res.data;

                pushToDashboard(data);
            }

            setLoading(false);
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);
        if (formisValid) {
            isNameRequest ? validateUser() : intiateChatForUser();
        }
        setErrors(formErrors);
    };

    const { email, fullname } = request;

    return (
        <div>
            <h5 className='signin-header'>{title ? title : "Hello,"}</h5>
            <p className='signin-sub__text'>
                {subTitle ? (
                    subTitle
                ) : (
                    <>
                        Welcome to <strong>{teamName}</strong>. To enable us
                        solve your issue easily, kindly enter your email address
                        below.
                    </>
                )}
            </p>
            <form onSubmit={handleSubmit}>
                <ErrorDialog
                    show={Boolean(errorMssg)}
                    message={errorMssg}
                    hide={() => setErrorMssg()}
                />
                <Input
                    type={`${isNameRequest ? "text" : "email"}`}
                    placeholder={`${isNameRequest
                            ? "Enter your name"
                            : "Enter your email address"
                        }`}
                    name={`${isNameRequest ? "fullname" : "email"}`}
                    id={`${isNameRequest ? "fullname" : "email"}`}
                    inputClass='py-3 email__input'
                    data-label={`${isNameRequest ? "Name" : "Email address"}`}
                    value={`${isNameRequest ? fullname : email}`}
                    label={`${isNameRequest ? "Full name" : "Email"}`}
                    onChange={handleChange}
                    isErr={isNameRequest ? errors?.fullname : errors?.email}
                    errMssg={isNameRequest ? errors?.fullname : errors?.email}
                    hideLabel={true}
                />
                <Button
                    type='submit'
                    text={"Continue"}
                    classType='primary'
                    otherClass='my-3 w-100 submit__email'
                    loading={loading}
                />
            </form>
            {bottomText && (
                <div className='info__section'>
                    <ReactSVG src={imageLinks.svg.info} />
                    <p>{bottomText}</p>
                </div>
            )}
        </div>
    );
};

export default EmailForm;
