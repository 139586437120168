const ChatToastNotification = ({ title, body }) => {
    return (
        <>
            <div>
                <h6>{title}</h6>
                <p>{body}</p>
            </div>
        </>
    );
};

export default ChatToastNotification;
