import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { messageTypes, branchOptionIds } from "../../enums";
import MessageBranchOption from "./MessageBranchOption/MessageBranchOption";
import ScheduledBranchOption from "./ScheduledBranchOption/ScheduledBranchOption";
import { defaultTemplates } from "hoc/AppTemplateWrapper/enum";
import PoweredBy from "components/common/PoweredBy/PoweredBy";
import { dataQueryStatus } from "utils/formatHandlers";
import SeeMoreOptions from "./SeeMoreOptions/SeeMoreOptions";
import "./MessageOptions.scss";


const { RELAXED } = defaultTemplates;
const { CONVERSATION } = messageTypes;
const { NO_ACTION } = branchOptionIds;
const { LOADING } = dataQueryStatus;
const MessageOptions = ({
    messageType,
    options,
    handleMessageOptionSelect,
    handleOptConversation,
    selectedOption,
    messageIndex,
    messagesDepth,
    deliveryDate,
    lastMessage,
    status,
    mssgSendStatus,
}) => {
    const isInvestigating = options
        ?.reverse()
        ?.find((option) => option?.branchOptionId === NO_ACTION);

    const [showAll, toggleShowAll] = useState(isInvestigating ? false : true);

    const { defaultTemplate } = useSelector(
        (state) => state?.chat?.chatSettings
    );

    const isRelaxedTemplate = defaultTemplate === RELAXED;

    const [branchOptions, setBranchOptions] = useState([]);

    useEffect(() => {
        if (isInvestigating) {
            const sortKey = "similarityScore";
            setBranchOptions(
                options
                    ?.sort((a, b) =>
                        isInvestigating
                            ? b?.[sortKey] - a?.[sortKey]
                            : a?.[sortKey] - b?.[sortKey]
                    )
                    .slice(0, showAll ? undefined : 2)
            );
        } else {
            const optionByOrder = options
                ?.filter((option) => option.order !== null)
                ?.sort((a, b) => a.order - b.order);
            const optionByCreatedDate = options
                ?.filter((option) => option.order === null)
                ?.sort(
                    (a, b) =>
                        +new Date(a.createdDate) - +new Date(b.createdDate)
                );
            const sortedOptions =
                optionByOrder && optionByCreatedDate
                    ? [...optionByOrder, ...optionByCreatedDate]
                    : [];
            setBranchOptions(sortedOptions);
        }
        // eslint-disable-next-line
    }, [showAll]);

    return (
        <div className='options__container col-lg-5 col-md-7 col-12'>
            <div className='options__group options__group__investigate'>
                {branchOptions?.map((option, index) => {
                    return option?.isScheduled ? (
                        <ScheduledBranchOption
                            key={index}
                            data={option}
                            messageIndex={messageIndex}
                            messagesDepth={messagesDepth}
                            branchOptionId={option?.branchOptionId}
                            selectedOption={selectedOption}
                            lastMessage={lastMessage}
                            handleMessageOptionSelect={() =>
                                messageType === CONVERSATION
                                    ? handleOptConversation(option)
                                    : handleMessageOptionSelect(option)
                            }
                            deliveryDate={deliveryDate}
                            status={status}
                            mssgOptionLoading={
                                isRelaxedTemplate && mssgSendStatus === LOADING
                            }
                        />
                    ) : (
                        <MessageBranchOption
                            key={index}
                            data={option}
                            messageIndex={messageIndex}
                            messagesDepth={messagesDepth}
                            branchOptionId={option?.branchOptionId}
                            selectedOption={selectedOption}
                            lastMessage={lastMessage}
                            mssgOptionLoading={
                                isRelaxedTemplate && mssgSendStatus === LOADING
                            }
                            status={status}
                            handleMessageOptionSelect={() =>
                                messageType === CONVERSATION
                                    ? handleOptConversation(option)
                                    : handleMessageOptionSelect(option)
                            }
                        />
                    );
                })}
                {!showAll && (
                    <SeeMoreOptions onClick={() => toggleShowAll(!showAll)} />
                )}
            </div>

            {isRelaxedTemplate && (
                <PoweredBy otherClassName='white__background options__footer' />
            )}
        </div>
    );
};

export default MessageOptions;