import { ReactSVG } from "react-svg";
import assets from "../../../assets/images";
import "./AgentImage.scss";
import ImageView from "components/common/ImageView/ImageView";

export const AgentImage = ({
    active,
    src,
    alt,
    width = "20px",
    height = "20px",
    showAvatar = true,
}) => {
    return (
        <>
            {showAvatar ? (
                <div className='position-relative'>
                    <ImageView
                        src={src}
                        alt={alt}
                        width={width}
                        height={height}
                        className='rounded-circle'
                    />
                    {active && (
                        <ReactSVG
                            src={assets.svg.success}
                            className='position-absolute top-50 end-0'
                        />
                    )}
                </div>
            ) : (
                <div className='dummy__avatar__wrapper'></div>
            )}
        </>
    );
};
